import { config } from "@config";

export default {
  skills: true,
  ai: true,
  // NOT_PRODUCTION_READY
  groupProvisioning: false,
  fetchOnWindowFocus: false,
  unitUsersMassActions: !config.isProd(),
  openSesame: !config.isProd(),
} as const;
